import React from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import "./bodyFormInscripcionDesarrolloEmpresarial.scss";
import FormInscripcionDesarrolloEmpresarial from "../form-inscripcion-desarrollo-empresarial/formInscripcionDesarrolloEmpresarial";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";

interface IBodyForm {
    tituloPrincipal: string;
    descripcionFormulario: any;
    titulosFormulario: Array<any>;
    imagenesEmpleo: any;
}

const BodyFormInscripcionDesarrolloEmpresarial = (props: IBodyForm) => {
    const contentfulRenderOptions = {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children) => (
                <a
                    style={{ color: "#EE2B7B", textDecoration: "underline" }}
                    href={data.uri}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                >
                    {children}
                </a>
            ),
        },
        renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    };

    return (
        <div className="o-bodyForm-inscripcion">
            <Breadcrumb content={`Inicio / Ley de Emprendimiento`} />
            <div className={`o-bodyForm-inscripcion__header`}>
                <h1 className="o-bodyForm-inscripcion__header-title">
                    {props.tituloPrincipal}
                </h1>
                <p className="o-bodyForm-inscripcion__header-subtitle">
                    {documentToReactComponents(
                        props.descripcionFormulario.json,
                        contentfulRenderOptions
                    )}
                </p>
            </div>
            <div className={`o-bodyForm-inscripcion__cont`}>
                <div className="o-bodyForm-inscripcion__cont-form">
                    <FormInscripcionDesarrolloEmpresarial
                        {...props.titulosFormulario}
                    />
                </div>
            </div>
        </div>
    );
};

export default BodyFormInscripcionDesarrolloEmpresarial;
