import React, { useState, useEffect, useContext } from "react";
import "./formInscripcionDesarrolloEmpresarial.scss";
import FormWizard from "../../molecules/form-wizard/form-wizard";
import Button from "../../atoms/button-demp/button";
import InputForm from "../../atoms/input-form-demp/inputForm";
import InputFiles from "../../atoms/input-files-demp/inputFiles";
import GlobalContext from "../../../contexts/globalContext";
import Loading from "../../atoms/loading/loading";
import Modal from "../../atoms/modal/modal";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { postForm } from "../../../services/form-demp.intermediation";
import Tooltip from "../../atoms/tooltip/tooltip";
import DOMPurify from "dompurify";
import ProgressBar from "../../atoms/progress-bar/progressBar";
import { getAccessToken } from "../../../services/accessToken";
import { rootCertificates } from "tls";

interface IFormPayload {
   respuestas: { preguntaContentfulId: string; respuesta: any }[];
   adjuntos: { archivoBase64: string; tipo: string }[];
}

const FormInscripcionDesarrolloEmpresarial = (props: any) => {
   const formFields = props;
   const context = useContext(GlobalContext);

   let defaultFormValues = {
      authorizedDataTerms: "SI",
      binaries: [],
   };

   let defaultMandatoryFieldsSec1: string[] = [];
   let defaultMandatoryFieldsSec2: string[] = [];
   let defaultMandatoryFieldsSec3: string[] = [];
   formFields[0].referenciaFormulario.map((field) => {
      defaultFormValues[field.nombreId] = "";
      if (field.obligatorio) {
         defaultMandatoryFieldsSec1.push(field.nombreId);
      }
   });
   formFields[1].referenciaFormulario.map((field) => {
      defaultFormValues[field.nombreId] = "";
      if (field.obligatorio) {
         defaultMandatoryFieldsSec2.push(field.nombreId);
      }
   });
   formFields[2].referenciaFormulario.map((field) => {
      defaultFormValues[field.nombreId] = "";
      if (field.obligatorio) {
         defaultMandatoryFieldsSec3.push(field.nombreId);
      }
   });
   const [mandatoryFieldsSec1, setMandatoryFieldsSec1] = useState(
      defaultMandatoryFieldsSec1
   );
   const [mandatoryFieldsSec2, setMandatoryFieldsSec2] = useState(
      defaultMandatoryFieldsSec2
   );
   const [mandatoryFieldsSec3, setMandatoryFieldsSec3] = useState(
      defaultMandatoryFieldsSec3
   );
   const mandatoryFieldsObj = {mandatoryFieldsSec1, mandatoryFieldsSec2, mandatoryFieldsSec3};

   const [formValues, setFormValues] = useState(defaultFormValues);
   const [successModelOpen, setSuccessModelOpen] = useState(false);
   const [errorModelOpen, setErrorModelOpen] = useState(false);
   const [errorText, setErrorText] = useState("");
   const [loading, setLoading] = useState(false);
   const [showErrors, setShowErrors] = useState(false);
   const [wizards, setWizards] = useState<any[]>([]);
   const [activeIdx, setActiveIdx] = useState(0);
   const [fileCount, setFileCount] = useState(0);
   const [labelText, setLabelText] = useState(true);

   const updateFormValue = (field: string, value: any) => {
      if (field == "companyRegistration") {
         if (value == "Sí") {
            document.getElementById(
               "seccion-registroEmpresa"
            ).style.gridTemplateRows = "repeat(3, auto)";
            setLabelText(true);
         }
         if (value == "No") {
            document.getElementById(
               "seccion-registroEmpresa"
            ).style.gridTemplateRows = "repeat(2, auto)";
            setLabelText(false);
         }
      }
      if (field == "companyIncorporation") {
         if (value == "Persona natural.") {
            setLabelText(false);
         }
         if (value == "Persona jurídica.") {
            setLabelText(true);
         }
      }
      setFormValues({ ...formValues, [field]: value });
      if (!formValues.authorizedDataTerms) setFormValues({ ...formValues, ["authorizedDataTerms"]: "SI" });
      
   };

   const isDisabledSubmitButton = () => {
      return !Object.entries(formValues).every(
         ([key, value]) =>
            !mandatoryFieldsSec1
               .concat(mandatoryFieldsSec2)
               .concat(mandatoryFieldsSec3)
               .includes(key) ||
            (Array.isArray(value) ? value.length > 0 : value)
      );
   };

   const isDisabledContinueSec1 = () => {
      return !Object.entries(formValues).every(
         ([key, value]) => !mandatoryFieldsSec1.includes(key) || value
      );
   };

   const isDisabledContinueSec2 = () => {
      return !Object.entries(formValues).every(
         ([key, value]) =>
            !mandatoryFieldsSec2.includes(key) ||
            (Array.isArray(value) ? value.length > 0 : value)
      );
   };

   const createWizards = () => {
      let newWizards: any[] = [];
      for (let i = 0; i < 3; i++) {
         if (formFields[i]) {
            newWizards.push({
               description: formFields[i].tituloGeneral,
               idx: i,
               active: i === activeIdx,
            });
         }
      }
      document.querySelector(".m-form-wizard")?.scrollIntoView();
      setWizards([...newWizards]);
   };

   useEffect(() => {
      createWizards();
   }, [activeIdx]);

   const readFile = async (file) => {
      return new Promise((resolve) => {
         var reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            var fileType = file.name.match(
               /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx)$/i
            );
            updateFormValue("binaries", [
               ...formValues.binaries,
               {
                  fileBase64: reader.result.split(",")[1],
                  key: "HV" + fileCount,
                  type: fileType ? fileType[1].slice(1).toLowerCase() : "otro",
               },
            ]);
            setFileCount(fileCount + 1);
            resolve(true);
         };
      });
   };

   const toBase64 = async (files) => {
      for (let file of files) {
         await readFile(file[0]);
      }
   };

   useEffect(() => {
      toBase64(formValues["attachmentsIncome"]);
   }, [formValues["attachmentsIncome"]]);

   useEffect(() => {
      toBase64(formValues["attachmentsRegister"]);
   }, [formValues["attachmentsRegister"]]);
   
   useEffect(() => {
      toBase64(formValues["attachmentsContributions"]);
   }, [formValues["attachmentsContributions"]]);

   useEffect(() => {
      toBase64(formValues["attachmentsLetterInterest"]);
   }, [formValues["attachmentsLetterInterest"]]);

   useEffect(() => {
      if (formValues["companyRegistration"] == "Sí") {
         setFormValues({ ...formValues, nit: localStorage.getItem("docNum" )});
         setMandatoryFieldsSec2([
            ...new Set(
               mandatoryFieldsSec2
                  .concat(["nit", "companyIncorporation"])
                  .filter((field) => field != "rut")
            ),
         ]);
      }
      if (formValues["companyRegistration"] == "No") {
         setFormValues({
            ...formValues,
            rut: localStorage.getItem("docNum"),
            companyIncorporation: "",
            legalForm: "",
         });
         setMandatoryFieldsSec2([
            ...new Set(
               mandatoryFieldsSec2
                  .concat(["rut"])
                  .filter((field) => !["nit", "companyIncorporation", "legalForm"]
                  .includes(field)
               )
            ),
         ]);
      }
   }, [formValues["companyRegistration"]]);

   useEffect(() => {
      if (formValues["companyIncorporation"] == "Persona jurídica.") {
         updateFormValue("legalForm", "");
         setMandatoryFieldsSec2([
            ...new Set(mandatoryFieldsSec2.concat(["legalForm"])),
         ]);  
         setFormValues({ ...formValues, companyName: localStorage.getItem("nameCompany") });
      }
   }, [formValues["companyIncorporation"]]);

   useEffect(() => {
      updateFormValue("businessProgramLine", "");
   }, [formValues["programLine"]]);

   useEffect(() =>{
      if (formValues["businessProgramLine"] == "Servicios compartidos"){
         updateFormValue("ProgramLineSharedServ", "");
         setMandatoryFieldsSec3([
            ...new Set(mandatoryFieldsSec3.concat(["ProgramLineSharedServ"])),
         ]);
      }
   },[formValues["businessProgramLine"]])

   useEffect(() => {
      if (context.gigyaContext.gigyaAccount) {
         const birthDate = new Date(
            context.gigyaContext.gigyaAccount.profile.birthYear,
            context.gigyaContext.gigyaAccount.profile.birthMonth - 1,
            context.gigyaContext.gigyaAccount.profile.birthDay
         );
         const fullname =
            context.gigyaContext.gigyaAccount.profile.firstName +
            " " +
            context.gigyaContext.gigyaAccount.profile.lastName;
         setFormValues({
            ...formValues,
            fullName: fullname,
            docType: context.gigyaContext.gigyaAccount.data.docType,
            docNum: context.gigyaContext.gigyaAccount.data.docNumber,
            gender: context.gigyaContext.gigyaAccount.profile.gender,
            email: context.gigyaContext.gigyaAccount.profile.email,
            birthDate: birthDate.toISOString().split("T")[0],
            city: context.gigyaContext.gigyaAccount.profile.city,
            phone: context.gigyaContext.gigyaAccount.data.phone || "",
         });
      }
   }, [context.gigyaContext.gigyaAccount]);

   // Valida los campos obligatorios esten diligenciados de lo contrario activa el mensaje de error en el campo
   const errorDetected = (input): boolean => {
      if (input.nombreId == "mobile" && formValues.mobile?.length != 10 && showErrors){
         input.mensajeError = "* El número celular debe ser de 10 dígitos";
         return true;
      }
      if (
         input.nombreId == "companyDescription" &&
         formValues[input.nombreId].length > 500 &&
         formValues[input.nombreId] != "" &&
         showErrors
      ) {
         input.mensajeError =
            "* Has excedido el límite de caracteres: " +
            (500 - formValues[input.nombreId].length);
         return true;
      }
      if (
         mandatoryFieldsSec1
            .concat(mandatoryFieldsSec2)
            .concat(mandatoryFieldsSec3)
            .includes(input.nombreId) &&
         formValues[input.nombreId] == "" &&
         showErrors
      ) {
         return true;
      }
   };

   const validateSigleField = (field) => {
      if (field == 'mobile') {
         return !(formValues.mobile?.length == 10);
      }
      if (formValues[field] == "") {
         return true;
      }
      return false;
   } 

   const goToMissingMandatoryField = () => {
      for (const field of mandatoryFieldsSec1
         .concat(mandatoryFieldsSec2)
         .concat(mandatoryFieldsSec3)) {
         if (validateSigleField(field)) {
            document.getElementById(field)?.scrollIntoView({
               behavior: "auto",
               block: "center",
               inline: "center",
            });
            break;
         }
      }
   };
   const validateFormSection = (section:number) => {
      for (const field of mandatoryFieldsObj["mandatoryFieldsSec" + (section + 1)]) {
         if (validateSigleField(field)) {
            return false;
         }
      }
      return true;
   };

   const canContinue = () => {
      if (activeIdx == 0) {
         if (!isDisabledContinueSec1() && validateFormSection(activeIdx)) {
            setActiveIdx(activeIdx + 1);
            setShowErrors(false);
         } else {
            goToMissingMandatoryField();
            setShowErrors(true);
         }
      }
      if (activeIdx == 1) {
         if (!isDisabledContinueSec2() && validateFormSection(activeIdx)) {
            setActiveIdx(activeIdx + 1);
            setShowErrors(false);
         } else {
            goToMissingMandatoryField();
            setShowErrors(true);
         }
      }
   };

   const canSubmit = () => {
      if (!isDisabledSubmitButton()) {
         submitForm();
         setShowErrors(false);
      } else {
         goToMissingMandatoryField();
         setShowErrors(true);
      }
   };

   const renderText = (tooltip) => {
      if (tooltip) {
         return documentToReactComponents(
            tooltip.json,
            contentfulRenderOptions
         );
      } else {
         return <></>;
      }
   };

   const splitText = (string, index) => {
      if (string.includes(";")) return string.split(";")[index];
      else return string;
   };

   const submitForm = async () => {
      const token = await (await getAccessToken()).data.data.id_token;
      let payload: IFormPayload = {
         respuestas: [],
         adjuntos: [],
      };
      setLoading(true);
      Object.entries(formValues).map(([key, value]) => {
            if(key !== "attachmentsIncome" && key !== "attachmentsRegister" && key !== "attachmentsContributions" && key !== "attachmentsLetterInterest") {
               payload.respuestas.push({
                  preguntaContentfulId: key,
                  respuesta: value,
               })}
            }
      );
      payload.respuestas = payload.respuestas.filter(
         (respuesta) => !["binaries"].includes(respuesta.preguntaContentfulId)
      );
      formValues.binaries.forEach((binarie: any) =>
         payload.adjuntos.push({
            archivoBase64: binarie.fileBase64,
            tipo: binarie.type,
         })
      );
      postForm(payload, token)
         .then((_response) => {
            window.location.href ="/desarrollo-empresarial/gracias";
            setLoading(false);
         })
         .catch((err) => {
            if (err.response) {
               setErrorModelOpen(true);
               setErrorText(err.response.data.userMessage);
               setLoading(false);
            } else if (err.request) {
               setLoading(false);
               setErrorText(
                  "No fue posible comunicarse con el servicio, por favor intente nuevamente"
               );
            } else {
               setLoading(false);
               setErrorText(
                  "No fue posible comunicarse con el servicio, por favor intente nuevamente"
               );
            }
         });
   };

   const createEmptyInput = (input) => {
      return (
         <div
            className={`input__container__empty`}
            id={`container_${input.nombreId}`}
         ></div>
      );
   };

   const createInput = (input, index) => {
      switch (input.tipoCampo) {
         case "file":
            return (
               <div className="input__container__first">
                  <div className={`input__container__h6`}>
                     <label
                        className={`${
                           input.inputPrincipal &&
                           mandatoryFieldsSec1
                              .concat(mandatoryFieldsSec2)
                              .concat(mandatoryFieldsSec3)
                              .includes(input.nombreId)
                              ? "required"
                              : ""
                        }`}
                     >
                        {input.inputPrincipal &&
                           splitText(input.inputPrincipal, 1)}
                     </label>
                  </div>
                  {input.textoTerminos && renderText(input.textoTerminos)}
                  {input.tituloBotonTerminos && (
                     <small
                        className="small-note"
                        dangerouslySetInnerHTML={{
                           __html: DOMPurify.sanitize(
                              input.tituloBotonTerminos,
                              { ADD_ATTR: ["target"] }
                           ),
                        }}
                     ></small>
                  )}
                  <span id={input.nombreId}></span>
                  <InputFiles
                     key={`inp${index}`}
                     title={input.inputPrincipal}
                     nameId={input.nombreId}
                     value={formValues[input.nombreId]}
                     handleChange={(e) => updateFormValue(input.nombreId, e)}
                     isError={errorDetected(input)}
                     errorMessage={input.mensajeError}
                     maxFiles={1}
                     binaries={formValues.binaries}
                     validTypes={["png", "jpg", "jpeg", "pdf", "doc", "docx"]}
                  />
               </div>
            );
         default:
            return (
               <div
                  className={`input__container${
                     [
                        "programLine",
                        "companySize",
                        "businessProgramLine",
                     ].includes(input.nombreId)
                        ? "__first"
                        : ""
                  }`}
                  key={index}
                  id={`container_${input.nombreId}`}
               >
                  <div className={`input__container__h6`}>
                     <label
                        className={`${
                           input.inputPrincipal &&
                           mandatoryFieldsSec1
                              .concat(mandatoryFieldsSec2)
                              .concat(mandatoryFieldsSec3)
                              .includes(input.nombreId)
                              ? "required"
                              : ""
                        }`}
                     >
                        {labelText
                           ? input.inputPrincipal &&
                             splitText(input.inputPrincipal, 1)
                           : input.inputPrincipal &&
                             splitText(input.inputPrincipal, 0)}
                        {input.tituloBotonTerminos && (
                           <Tooltip
                              id={`tooltip_${input.nombreId}`}
                              title={
                                 !input.inputPrincipal.includes("CIIU")
                                    ? input.inputPrincipal
                                    : null
                              }
                              text={input.tituloBotonTerminos}
                           ></Tooltip>
                        )}
                     </label>
                  </div>
                  {!["facebook", "instagram", "youtube", "webPage"].includes(
                     input.nombreId
                  ) &&
                     input.textoTerminos &&
                     renderText(input.textoTerminos)}
                  <InputForm
                     key={`inp${index}`}
                     title={input.inputPrincipal}
                     type={input.tipoCampo}
                     nameId={input.nombreId}
                     value={formValues[input.nombreId]}
                     handleChange={(e) => updateFormValue(input.nombreId, e)}
                     isError={errorDetected(input)}
                     errorMessage={input.mensajeError}
                     options={input.listaSelect || null}
                     gigyaAccount={context.gigyaContext.gigyaAccount}
                     selectOtherTitle={
                        ["city", "companyCity"].includes(input.nombreId)
                           ? "Ingrese otro municipio"
                           : ""
                     }
                  />
               </div>
            );
      }
   };

   const createSection = (title, fields) => {
      return (
         <div
         className={`o-form-inscripcion-desarrollo-empresarial__group-cont ${
            title == "tamañoEmpresa" ? "field-section-files " : "field-section "
            }parent-input-files`}

            id={"seccion-" + title}

         >
            {title == "redesEmpresa" && (
               <div className="input__container__first">
                  <div className={`input__container__h6`}>
                     <label>Redes sociales</label>
                  </div>
                  <p className="small-note">
                     Ingresa la URL de la red social de tu empresa o escribe N/A
                     en caso de no contar con ella.
                  </p>
               </div>
            )}
            {fields.map((field, i) => {
               let inputField: JSX.Element | null;
               switch (field.nombreId) {
                  case "nit": {
                     if (formValues["companyRegistration"] == "Sí") {
                        inputField = createInput(field, i);
                     } else if (formValues["companyRegistration"] == "No") {
                        inputField = null;
                     } else {
                        inputField = createEmptyInput(field);
                     }
                     break;
                  }
                  case "rut": {
                     if (formValues["companyRegistration"] == "No") {
                        inputField = createInput(field, i);
                     } else {
                        inputField = null;
                     }
                     break;
                  }
                  case "companyIncorporation": {
                     if (formValues["companyRegistration"] == "Sí") {
                        inputField = createInput(field, i);
                     } else {
                        inputField = null;
                     }
                     break;
                  }
                  case "legalForm": {
                     inputField =
                        formValues["companyIncorporation"] ==
                           "Persona jurídica." &&
                        formValues["companyRegistration"] == "Sí"
                           ? createInput(field, i)
                           : null;
                     break;
                  }
                  case "businessProgramLine":{
                     inputField = null;
                     if(formValues["programLine"] == "Oferta general" && field.inputPrincipal == "Oferta general"){
                        inputField = createInput(field, i);
                     }
                     if (formValues["programLine"] == "Oferta específica" && field.inputPrincipal == "Oferta específica"){
                        inputField = createInput(field, i);
                     }
                     if (formValues["programLine"] == "Oferta acompañamiento ELPAUER" && field.inputPrincipal == "Oferta acompañamiento ELPAUER"){
                        inputField = createInput(field, i);
                     }
                     break;
                  }
                  case "ProgramLineSharedServ": {
                     inputField =
                        formValues["businessProgramLine"] ==
                           "Servicios compartidos"
                           ? createInput(field, i)
                           : null;
                     break;
                  }

                  default: {
                     inputField = createInput(field, i);
                     break;
                  }
               }
               return inputField;
            })}
         </div>
      );
   };

   const contentfulRenderOptions = {
      renderNode: {
         [INLINES.HYPERLINK]: ({ data }, children) => (
            <a
               style={{ color: "#EE2B7B" }}
               href={data.uri}
               target={"_blank"}
               rel={"noopener noreferrer"}
            >
               {children}
            </a>
         ),
         [BLOCKS.PARAGRAPH]: (node, children) => {
            return <p className="small-note">{children}</p>;
         },
         [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            return (
               <p>
                  <img
                     src={`https://${node.data.target.fields.file["en-US"].url}`}
                     height={260}
                     width={250}
                     alt={node.data.target.fields.description}
                  />
               </p>
            );
         },
      },
      renderText: (text) =>
         text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
   };

   const renderRichTextField = (document) => {
      if (document) {
         return documentToReactComponents(
            document.json,
            contentfulRenderOptions
         );
      } else {
         return <></>;
      }
   };

   const groupBy = (list, key) => {
      return list.reduce(function (rv, x) {
         (rv[x[key]] = rv[x[key]] || []).push(x);
         return rv;
      }, {});
   };

   const formBody = () => {
      return (
         <React.Fragment>
            <FormWizard
               wizards={wizards}
               title="Realiza tu inscripción en tres pasos"
               subtitle="Recuerda que los campos señalados con el signo (*) son obligatorios."
               id="formWizard"
            />
            {activeIdx === 0 && (
               <div
                  id="groupIntermediacion0"
                  className="o-form-inscripcion-desarrollo-empresarial__group--open"
               >
                  {Object.entries(
                     groupBy(formFields[0].referenciaFormulario, "seccion")
                  ).map((seccion) => createSection(seccion[0], seccion[1]))}
               </div>
            )}
            {activeIdx === 1 && (
               <div
                  id="groupIntermediacion1"
                  className="o-form-inscripcion-desarrollo-empresarial__group--open"
               >
                  <div className="o-form-inscripcion-desarrollo-empresarial__group-cont">
                     {Object.entries(
                        groupBy(formFields[1].referenciaFormulario, "seccion")
                     ).map((seccion) => createSection(seccion[0], seccion[1]))}
                  </div>
               </div>
            )}
            {activeIdx === 2 && (
               <div
                  id="groupIntermediacion2"
                  className="o-form-inscripcion-desarrollo-empresarial__group--open"
               >
                  <div className="o-form-inscripcion-desarrollo-empresarial__group-cont">
                     {formFields &&
                        renderRichTextField(formFields[2].descripcion)}
                     {Object.entries(
                        groupBy(formFields[2].referenciaFormulario, "seccion")
                     ).map((seccion) => createSection(seccion[0], seccion[1]))}
                  </div>
               </div>
            )}
            {
               <div className="o-form-inscripcion-desarrollo-empresarial__bottom">
                  <div className="o-form-inscripcion-desarrollo-empresarial__bottom-btns">
                     <div className="o-form-inscripcion-desarrollo-empresarial__bottom-btn">
                        <Button
                           id={"btn-enviar"}
                           type={"button"}
                           classname={`primary `}
                           text={"Volver" || "Send"}
                           isDisabled={false}
                           onClickAction={() => {
                              if (activeIdx == 0) {
                                 window.location.href = window.location.href =
                                    "/desarrollo-empresarial/autorizacion-datos-y-condiciones";
                              } else {
                                 setActiveIdx(activeIdx - 1);
                              }
                           }}
                        />
                        {activeIdx === 2 && (
                           <Button
                              id={"btn-enviar"}
                              type={"button"}
                              classname={`secondary `}
                              text={"Finalizar" || "Send"}
                              isDisabled={false}
                              gtmMark={
                                 "gtmFormularioTrabajoPropositoFormularioEnviar-3"
                              }
                              onClickAction={() => canSubmit()}
                           />
                        )}
                        {activeIdx < 2 && (
                           <Button
                              id={"btn-enviar"}
                              type={"button"}
                              classname={`secondary `}
                              text={"Continuar" || "Send"}
                              isDisabled={false}
                              onClickAction={() => canContinue()}
                           />
                        )}
                     </div>
                  </div>
               </div>
            }
         </React.Fragment>
      );
   };

   return (
      <div className="o-form-inscripcion-desarrollo-empresarial">
         {!context.gigyaContext.gigyaAccount ||
         loading ||
         localStorage.getItem("isAffiliatedCompany") !== "true" ||
         localStorage.getItem("isLegalRepresentative") !== "true" ||
         localStorage.getItem("isDataProcessingAuthorized") !== "true" ||
         localStorage.getItem("isParticipationConditionsAuthorized") !==
            "true" ? (
            <Loading />
         ) : (
            formBody()
         )}

         {
            <Modal open={successModelOpen} onClose={() => {}}>
               <div className="o-form-inscripcion-desarrollo-empresarial-requirements">
                  <div className="o-form-inscripcion-desarrollo-empresarial-requirements__content">
                     <div className="o-form-inscripcion-desarrollo-empresarial-requirements__content__icon">
                        <i
                           className="icon__check icon-close"
                           onClick={() => setSuccessModelOpen(false)}
                        />
                     </div>
                     <p className="o-form-inscripcion-desarrollo-empresarial-requirements__content__info">
                        Gracias por diligenciar la información. A partir de este
                        momento pasaremos a una verificación de los datos, y en
                        caso de que cumplas con los requisitos habilitantes nos
                        estaremos comunicando contigo vía correo electrónico.
                     </p>
                     <Button
                        id={"btn-continuar"}
                        type={"button"}
                        classname={`secondary`}
                        text={"Continuar" || "Send"}
                        isDisabled={false}
                        onClickAction={() => {
                           setSuccessModelOpen(false);
                        }}
                     />
                  </div>
               </div>
            </Modal>
         }
         {
            <Modal open={errorModelOpen} onClose={() => {}}>
               <div className="o-form-inscripcion-desarrollo-empresarial-requirements">
                  <div className="o-form-inscripcion-desarrollo-empresarial-requirements__content">
                     <div className="o-form-inscripcion-desarrollo-empresarial-requirements__content__icon">
                        <i
                           className="icon__check icon-close"
                           onClick={() => setErrorModelOpen(false)}
                        />
                     </div>
                     <p className="o-form-inscripcion-desarrollo-empresarial-requirements__content__info">
                        {errorText}
                     </p>
                     <Button
                        id={"btn-continuar"}
                        type={"button"}
                        classname={`secondary`}
                        text={"Cerrar" || "Close"}
                        isDisabled={false}
                        onClickAction={() => {
                           setErrorModelOpen(false);
                        }}
                     />
                  </div>
               </div>
            </Modal>
         }
         <ProgressBar
            formValues={formValues}
            requiredValues={mandatoryFieldsSec1
               .concat(mandatoryFieldsSec2)
               .concat(mandatoryFieldsSec3)}
            step={activeIdx + 1}
         ></ProgressBar>
      </div>
   );
};

export default FormInscripcionDesarrolloEmpresarial;
