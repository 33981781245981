import React from "react";
import Layout from "../../components/organisms/layout/layout";
import SEO from "../../shared/seo";
import BodyFormInscripcionDesarrolloEmpresarial from "../../components/organisms/body-form-inscripcion-desarrollo-empresarial/bodyFormInscripcionDesarrolloEmpresarial";
import { useStaticQuery, graphql } from "gatsby";

const InscripcionDesarrolloEmpresarialPage = () => {
   const data = useStaticQuery(graphql`
      query contentfulFormInscripcionDesarrolloEmpresarial {
         allContentfulContactenos(
            filter: {
               titulo: {
                  eq: "Información Contáctanos Inscripción Desarrollo empresarial"
               }
            }
         ) {
            nodes {
               tituloPrincipal
               descripcionFormulario {
                  json
               }
               titulosFormulario {
                  tituloGeneral
                  descripcion {
                     json
                  }
                  referenciaFormulario {
                     tipoCampo
                     listaSelect
                     mensajeError
                     inputPrincipal
                     nombreId
                     tituloBotonTerminos
                     textoTerminos {
                        json
                     }
                     obligatorio
                     seccion
                  }
               }
               imagenesEmpleo {
                  file {
                     url
                  }
                  title
                  fluid(maxWidth: 960, quality: 75) {
                     src
                     srcSet
                     base64
                     aspectRatio
                     sizes
                  }
               }
            }
         }
      }
   `);

   console.log(data, "DATA CONTENT");

   return (
      <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
         <SEO
            title="Desarrollo Empresarial - Inscripción Programa Desarrollo Empresarial"
            lang="es"
         />
         <BodyFormInscripcionDesarrolloEmpresarial
            {...data.allContentfulContactenos.nodes[0]}
         />
      </Layout>
   );
};

export default InscripcionDesarrolloEmpresarialPage;
