import React from "react";
import "./form-wizard.scss";

interface WizardInterface {
   wizards: { idx: number; description: string; active: boolean }[];
   title: string;
}

const FormWizard = ({ wizards, title }: WizardInterface) => {
   const drawWizards = (
      idx: number,
      description: string,
      active: boolean,
      i: number
   ) => {
      return (
         <div
            className={`m-form-wizard-body${active ? "-active" : ""}`}
            key={i}
            style={{
               flexBasis: `${String(Math.round(100 / wizards.length))}%`,
            }}
         >
            <div className="m-form-wizard-components">
               <div className="m-form-wizard-number">{idx + 1}</div>
               <div className="m-form-wizard-description">{description}</div>
            </div>
            <div className="m-form-wizard-line"></div>
         </div>
      );
   };

   return (
      <div className="m-form-wizard">
         <div className="m-form-wizard-title">{title}</div>
         <div className="m-form-wizard-container">
            {wizards.map((inp, i) =>
               drawWizards(inp.idx, inp.description, inp.active, i)
            )}
         </div>
      </div>
   );
};

export default FormWizard;
