import React, { useState, useEffect, useRef } from "react"
import "./inputFiles.scss"

const InputFiles = (props: InputFilesInterface) => {

	const {
		value,
		nameId,
		tabIndex,
		handleChange,
		maxFiles,
		isError,
		errorMessage,
		binaries,
		validTypes,
	} = props;

	const [filesArr, setFilesArr] = useState<any>(value || []);

	let inputRef = useRef(null);

	const toastMsgs = {
		default: "",
		errorFileSize: "El archivo debe pesar menos de 5MB",
		errorFileType: "El archivo debe tener el formato adecuado",
		successDelete: "El archivo se eliminó exitosamente"
	}

	const [toastMsg, setToastMsg] = useState<any>(toastMsgs.default);
	const [isFile, setisFile] = useState(false)

	const verifyType = (file: any): boolean => {
		if (validTypes) {
			for (let type of validTypes) {
				if ((type === "pdf" && file[0].type.includes("pdf"))) {
					return true
				}
				if ((type === "png" && file[0].type.includes("png"))) {
					return true
				}
				if ((type === "jpg" && file[0].type.includes("jpg"))) {
					return true
				}
				if ((type === "jpeg" && file[0].type.includes("jpeg"))) {
					return true
				}
				if ((file[0].name.includes("doc"))) {
					return true
				}
			}
		} else if (file[0].type.includes("pdf")) {
			return true
		}
		return false
	}

	const handleClick = event => {
		inputRef.current.click();
	};

	useEffect(() => {
		setTimeout(() => {
			setToastMsg(toastMsgs.default);
		}, 4000);
	}, [toastMsg]);

	useEffect(() => {
		handleChange(filesArr, nameId);
	}, [filesArr]);

	const handleFileChange = (file: any) => {
		if (filesArr.length > maxFiles) {
			return;
		}
		if (file) {
			if (file[0].size < 5242880) {
				if (verifyType(file)) {
					setToastMsg(toastMsgs.default);
					filesArr && setFilesArr(filesArr => [...filesArr, file]);
					setisFile(true)
				} else {
					setToastMsg(toastMsgs.errorFileType);
				}
			}
			else {
				setToastMsg(toastMsgs.errorFileSize);
			}
		}
	}

	const handleRemoveFile = (fileName: any) => {
		filesArr && setFilesArr(filesArr.filter(item => item[0].name != fileName));
		binaries && handleChange([], "binaries")
		setToastMsg(toastMsgs.successDelete);
	}

	return (
		<div className="a-input-files">
			{
				(!isFile || (maxFiles && filesArr.length < maxFiles)) &&
				<>
					<div className="a-input-files__input">
						<div>
							<i className="icon-file1"></i>
							{/*<input
								onChange={e => { handleFileChange(e.target.files) }}
								type="file"
								id={nameId}
								name={nameId}
								tabIndex={tabIndex}
								ref={inputRef}
							/>*/}
							<button
								className="a-input-files__input-btn"
								onClick={handleClick}>
								Seleccionar archivo
							</button>
							<input
								onChange={e => { handleFileChange(e.target.files) }}
								type="file"
								id={nameId}
								name={nameId}
								tabIndex={tabIndex}
								ref={inputRef}
								style={{ display: 'none' }}
							/>
						</div>
						<span>Máximo 5MB</span>
					</div>
					{isError && <div><span className="a-input-files__error">{errorMessage}</span></div>}
				</>
			}


			{toastMsg &&
				<div className="a-toast-msg">
					<p> {toastMsg} </p>
				</div>
			}

			<div className="a-input-files__files">
				<p className="a-input-files__files-title">Archivos subidos </p>
				<ul className="a-input-files__files-list">
					{filesArr.map((item, i) => (
						<li key={`item${i}`}>
							<p> {item[0].name} </p>
							<button
								onClick={e => { e.preventDefault(); handleRemoveFile(item[0].name); !filesArr.length && setisFile(false) }}>
								<i className="icon-trash-alt"></i>
							</button>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

interface InputFilesInterface {
	title?: string,
	nameId: string,
	tabIndex?: number,
	value: any,
	handleChange: (value: any, name: string) => any,
	maxFiles?: number,
	isError?: boolean,
	errorMessage?: string,
	binaries?: any,
	validTypes?: any[],
}

export default InputFiles